angular
  .module("meanApp")
  .controller("GlobalController", ($scope, EventHelper) => {
    // Event listener for "openPushToFLEX"
    new EventHelper($scope).$on("openPushToFLEX", (event) => {
      // Safely update $scope within AngularJS digest cycle
      $scope.$apply(() => {
        $scope.uid = event.detail.uid;
        $scope.showConfirmFlexSendModal = true;
      });
    });
  });
